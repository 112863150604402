<template>
  <v-card
    v-bind="propsCompSheet"
    :loading="status.loading"
    class="d-flex flex-column"
    style="height: 100%"
    data-cy="documents-public-show"
  >
    <v-card-text v-if="!documentId">
      <div
        class="text-center text-h2 my-10"
        v-html="$t('documentsShares.notFound.title')"
      ></div>
      <div
        class="text-center text-h4 my-10"
        v-html="$t('documentsShares.notFound.message')"
      ></div>
      <div
        class="text-center text-h4 my-10"
        v-html="$t('documentsShares.notFound.action')"
      ></div>
    </v-card-text>

    <template v-if="documentId">
      <template v-if="isFullHtmlPage">
        <iframe
          :src="isLink ? documentDecoded() : documentEncoded()"
          class="iframeFullHtml"
        ></iframe>
      </template>
      <template v-else>
        <v-card-title>
          {{ document.name }}

          <v-spacer></v-spacer>

          <v-btn icon :href="getUrlDownload()">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-card-title>

        <!-- <v-card-text>
          <div class="d-flex align-center mb-4">
            <v-icon>mdi-file</v-icon>
            <div class="mr-4">{{ document.fileName }}</div>
          </div>
          <p>{{ document.description }}</p>
        </v-card-text> -->

        <div class="flex-grow-1">
          <object
            v-if="document.fileType === 'application/pdf'"
            :data="documentFileUrl"
            :type="document.fileType"
            width="100%"
            height="100%"
          ></object>
          <img
            v-else-if="document.fileType === 'image/png'"
            :src="documentFileUrl"
            width="100%"
            height="100%"
          />
          <img
            v-else-if="document.fileType === 'image/jpeg'"
            :src="documentFileUrl"
            width="100%"
            height="100%"
          />
          <object
            v-else-if="document.fileType === 'application/msword'"
            :data="documentFileUrl"
            :type="document.fileType"
            width="100%"
            height="100%"
          ></object>
          <object
            v-else-if="
              document.fileType ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            "
            :data="documentFileUrl"
            :type="document.fileType"
            width="100%"
            height="100%"
          ></object>
        </div>
      </template>
    </template>
  </v-card>
</template>

<script>
import ComponentStatus from "@/modules/base/componentStatus.mixin";
import Permissions from "@/modules/auth/mixins/Permissions.mixin";
import axios from "axios";

export default {
  name: "DocumentsPublicShow",
  mixins: [ComponentStatus, Permissions],
  props: {
    tokenId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    valid: false,
    documentId: null,

    documentBase64: "",
  }),
  computed: {
    document() {
      return this.$store.getters["documents/readById"](this.documentId);
    },
    documentFileUrl() {
      if (this.tokenId) {
        return `${process.env.VUE_APP_API_URL}/shared/${this.tokenId}/downloadfile`;
      }
      return "";
    },
    isFullHtmlPage() {
      if (
        this.document &&
        this.document.fileClass &&
        (this.document.fileClass == "html" || this.document.fileClass == "link")
      ) {
        return true;
      }
      return false;
    },
    isLink() {
      if (
        this.document &&
        this.document.fileClass &&
        this.document.fileClass == "link"
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    tokenId: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.setStatusReading();
          this.$store
            .dispatch("documents/readByToken", newValue)
            .then((result) => {
              this.documentId = result;
              this.setStatusReadSuccess();
            })
            .catch((/* error */) => {
              this.setStatusReadError();
            });
        }
      },
    },
    document() {
      if (this.document.organisationId) {
        this.$store.dispatch(
          "organisations/readAppearanceById",
          this.document.organisationId
        );
      }
    },
  },
  created() {},
  methods: {
    documentEncoded() {
      if (this.document.fileType && this.documentBase64) {
        return `data:${this.document.fileType};base64,${this.documentBase64}`;
      } else if (this.document.fileType) {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/shared/${this.tokenId}/base64`,
        }).then((result) => {
          this.documentBase64 = result.data;
          return `data:${this.document.fileType};base64,${this.documentBase64}`;
        });
      } else {
        return "";
      }
    },
    documentDecoded() {
      if (this.document.fileType && this.documentBase64) {
        return atob(this.documentBase64);
      } else if (this.document.fileType) {
        axios({
          method: "GET",
          url: `${process.env.VUE_APP_API_URL}/shared/${this.tokenId}/base64`,
        }).then((result) => {
          this.documentBase64 = result.data;
          return atob(this.documentBase64);
        });
      } else {
        return "";
      }
    },
    getUrlDownload() {
      return `${process.env.VUE_APP_API_URL}/shared/${this.tokenId}/download`;
    },
  },
};
</script>

<style scoped lang="scss">
.iframeFullHtml {
  height: 100%;
  border: 1px none #000;
}
</style>
